.event-chart {

    .footer-rechart {
        display: flex;
        align-items: center;
        color: grey;
        padding-left: 22px !important;

        .material-icons {
            color: rgb(229, 149, 19);
            font-size: 18px;
        }
    }

    .card-header-icon {
        background: linear-gradient(60deg, #D50003, #BF0D3E);
        position: absolute;
    }

    .card-content {
        .avg-date-range {
            padding: 0px 0px 0px 100px;
        }
    }

    .tab-container {
        display: flex;
        flex-wrap: wrap;

        .btn-group-sm {
            .btn {
                font-size: 13px;
                font-family: 'Montserrat';
                text-transform: none;
            }
        }

        .tabs {
            background: #1D617F;
            color: white;
        }

        .tabs:hover {
            background: #70bdd4;
        }

        .tabshover {
            background: #2E93B2;
        }
    }

    .tab-container {
        flex-direction: column;

        .dashbaord-chart-containt {

            .tabs-main-container {
                margin-top: 0px !important;
                word-break: break-all;
            }

            .tabs-containt {
                display: flex;
                align-items: center;

                .average-icon1 {
                    height: 15px;
                    width: 15px;
                    background-color: #0093b2;
                    padding-left: 15px;
                    margin-right: 5px;
                }

                .average-icon2 {
                    height: 15px;
                    width: 15px;
                    background-color: #ff9e1b;
                    padding-left: 15px;
                    margin-right: 5px;
                }

                .revenue-icon3 {
                    height: 15px;
                    width: 15px;
                    background-color: rgba(219, 190, 25, 0.776);
                    padding-left: 15px;
                }

                .revenue-icon4 {
                    height: 15px;
                    width: 15px;
                    background-color: green;
                    padding-left: 15px;
                }

                .revenue-icon5 {
                    height: 15px;
                    width: 15px;
                    background-color: #7B68EE;
                    padding-left: 15px;
                }

                .icons-containt {
                    .tooltip {
                        cursor: pointer;
                        position: relative;
                        font-size: 12px;
                        line-height: 0;
                        vertical-align: baseline;
                    }
                }

            }
        }
    }

    .line-chart-container {
        .recharts-tooltip-wrapper {
            border: 0.1px solid rgb(181, 179, 174);
            background: white;
            padding: 10px 10px 0px 10px;
            outline: 0;
        }

        .custom-tooltip {
            .date {
                color: #000;
                margin-bottom: 3px;
            }

            .average {
                color: #4c9ef5;
                margin-bottom: 5px;
            }

            .central-tooltip {
                color: #4c9ef5;
                margin-bottom: 5px;
                font-size: 1.3rem;
            }

            .y {
                color: red;
                margin-bottom: 5px;
            }

            .creative {
                color: rgba(219, 190, 25, 0.776);
                margin-bottom: 5px;

            }

            .conversions {
                color: green;
                margin-bottom: 5px;
            }

            .zipcode {
                color: #7B68EE;
                margin-bottom: 5px;
            }
        }

        .charts {
            position: relative;
        }

        .recharts-surface {
            font-family: 'Montserrat';
        }

        .rechart-text {
            position: absolute;
            top: 50%;
            left: 55%;
            transform: translate(-50%, -50%);
            color: grey;
            font-size: 20px;
            font-weight: 500;
            text-align: center;
        }

        .rechart-text-chart {
            position: absolute;
            top: 55%;
            left: 53%;
            transform: translate(-50%, -50%);
            color: grey;
            font-size: 20px;
            font-weight: 500;
            text-align: center;
        }
        .rechart-text-chart-full {
            left: 50%;
            top: 54%;
        }
    }
}

.city-chart {
    .card-content {
        .chart-container {
            padding: 0px;
            .download {
                padding-left: 15px;
                padding-right: 21px;
            }
            .charts {
                .header-row {
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    height: 50px;
                    display: flex;
                    align-items: center;

                    .percentage {
                        text-align: end;
                    }
                }

                .data-row {
                    height: 50px;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid #cecece;
                    padding-right: 40px;

                    .header-contain {
                        margin-top: 15px;
                        padding-left: 20px;
                    }

                    .bar {
                        margin-top: 3px;
                    }

                    .ant-progress {
                        margin-bottom: 0px;
                    }
                    .ant-progress-text{
                        position: absolute;
                        bottom: 20px;
                    }
                }

                .no-data {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 270px;
                    color: grey;
                    font-weight: 500;
                    font-size: 16px;
                }
            }
        }
    }

    .pagination {
        margin-top: 15px;
        .ant-pagination{
            .ant-pagination-item-active{
                background: #409fbd;
                color: white;
                border-radius: 50%;
                border: none;
                a{
                    color: white;
                }
            }
            .ant-pagination-item-active:focus,
            .ant-pagination-item-active:hover{
                border-radius: 50%;
            }
            .ant-pagination-item{
                border-radius: 50%;
            }
        }
    }
}