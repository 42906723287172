.dashboard-table {
    .card-title {
        font-size: 15px;
        font-weight: 900;
        font-family: 'Montserrat';
    }

    .dropdown-transaction-main {
        position: absolute;
        right: 130px;

        .dropdown-transaction-type {
            .form-group {
                margin: 0px;
            }
        }
    }

    .dropdown-transaction-prior {
        .form-group {
            margin: 0px;
        }
    }

    .fullscreen-button {
        margin: 0px;
        width: 41px;
        padding: 3px;
        z-index: 9999;
    }

    .dashboard-table-data {
        #pages-subPages-table {
            .dropdown {
                margin-bottom: 20px !important;

                .dropdown-menu {
                    padding: 8px;
                }
            }

            .ReactTable {
                .rt-table {
                    .rt-tbody {

                        // .rt-tr .rt-td:first-child {
                        //     text-align: start;
                        // }
                        .border-top-solid {
                            border-bottom: 1px solid #bdbcbc;
                        }

                        .border-solid {
                            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                        }
                    }

                    .rt-thead {
                        // .rt-tr .rt-th:first-child .rt-resizable-header-content {
                        //     text-align: start;
                        // }
                    }
                }

                .rt-noData {
                    left: 51%;
                    top: 61%;
                    padding: 0px;
                }
            }
        }
    }
}

.ant-modal.css-dev-only-do-not-override-fpg3f5.dashboard-table-model {
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    z-index: 9999 !important;

    .ant-modal-content {
        width: 100vw;
        height: 100vh;
        border-radius: 0px !important;

        .ant-modal-body {
            margin-top: 60px;

            #pages-subPages-table {
                .dropdown {
                    margin-bottom: 20px !important;

                    .dropdown-menu {
                        padding: 8px;
                    }
                }

                .ReactTable {
                    .rt-table {
                        .rt-tbody {
                            height: 540px !important;

                            .rt-tr .rt-td:first-child {
                                text-align: start;
                            }

                            .border-top-solid {
                                border-bottom: 1px solid #bdbcbc;
                            }

                            .border-solid {
                                border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                            }
                        }

                        .rt-thead {
                            .rt-tr .rt-th:first-child .rt-resizable-header-content {
                                text-align: start;
                            }
                        }
                    }

                    .rt-noData {
                        left: 48%;
                        top: 53%;
                        padding: 0px;
                    }
                }
            }
        }
    }
}

.ant-modal.css-fpg3f5.dashboard-table-model {
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    z-index: 9999 !important;

    .ant-modal-content {
        width: 100vw;
        height: 100vh;
        border-radius: 0px !important;

        .ant-modal-body {
            margin-top: 60px;

            #pages-subPages-table {
                .dropdown {
                    margin-bottom: 20px !important;

                    .dropdown-menu {
                        padding: 8px;
                    }
                }

                .ReactTable {
                    .rt-table {
                        .rt-tbody {
                            height: 540px !important;

                            .rt-tr .rt-td:first-child {
                                text-align: start;
                            }

                            .border-top-solid {
                                border-bottom: 1px solid #bdbcbc;
                            }

                            .border-solid {
                                border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                            }
                        }

                        .rt-thead {
                            .rt-tr .rt-th:first-child .rt-resizable-header-content {
                                text-align: start;
                            }
                        }
                    }

                    .rt-noData {
                        left: 48%;
                        top: 53%;
                        padding: 0px;
                    }
                }
            }
        }
    }
}