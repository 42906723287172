.traffic-trends {
    .traffic-trends-container {
        .card {
            .card-header {
                display: flex;
                align-items: center;
                background: linear-gradient(60deg, #006272, #003b49) !important;
                box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(36, 187, 167, 0.4) !important;
            }

            .card-content {
                .line-chart-container {
                    .recharts-tooltip-wrapper {
                        border: 0.1px solid rgb(181, 179, 174);
                        background: white;
                        padding: 10px 10px 0px 10px;
                        outline: 0;
                    }

                    .custom-tooltip {

                        .average {
                            color: #4c9ef5;
                            margin-bottom: 5px;
                        }
                    }

                    .rechart-text {
                        position: absolute;
                        top: 50%;
                        left: 55%;
                        transform: translate(-50%, -50%);
                        color: grey;
                        font-size: 20px;
                        font-weight: 500;
                        text-align: center;
                    }

                    .rechart-text-chart {
                        position: absolute;
                        top: 45%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        color: grey;
                        font-size: 20px;
                        font-weight: 500;
                        text-align: center;
                    }

                }
            }
        }
    }
}

.state-chart {
    .card-content {
        .chart-container {
            .charts {
                .header-row {
                    background: #f9f9f9;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    border: 1px solid #cecece;
                    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

                    .percentage {
                        text-align: end;
                        margin-right: 15px;
                    }
                }

                .data-row {
                    background: #ffffff;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    border: 1px solid #cecece;
                    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

                    .header-contain {
                        margin-top: 15px;
                        padding-left: 20px;
                    }

                    .bar {
                        margin-top: 3px;
                    }

                    .ant-progress {
                        margin-bottom: 0px;
                    }

                    .ant-progress-text {
                        position: absolute;
                        bottom: 20px;
                    }
                }

                .progress-bars {
                    .table-heading {
                        display: flex;
                        justify-content: space-between;
                        align-items: self-end;
                        margin-right: 6%;
                    }

                }
            }
        }

        .line-chart-container {
            .rechart-text {
                top: 75% !important;
                left: 50% !important;
            }
        }

    }

    .pagination {
        margin-top: 15px;
    }
}