.abandoned-cart{
    .utm-leader-board-container{
        .card {
            .card-header{
                display: flex;
                align-items: center;
                background: linear-gradient(60deg,#006272,#003b49) !important;
                box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(36, 187, 167, 0.4) !important;
            }
        }
    }
}
.dynamic-card {
    .utm-leader-board-container{
        .card {
            .card-content{
                .transaction-range-container{
                    .ReactTable{
                        .rt-noData{
                            left: 45%;
                            top: 46%;
                        }
                    }
                }
            }
        }
    }
}