#pages-subPages-table {
    .ReactTable {
        .rt-table {
            .-headerGroups {
                display: none;
            }

            .-header {
                .rt-tr:not(:last-child) {
                    .rt-th {
                        background-color: #337ab7;
                        font-weight: bolder !important;
                        color: #fff;
                    }
                }
                .rt-tr:last-child {
                    .rt-th {
                        background-color: #337ab7;
                        color: #ffffff;
                        

                        .rt-resizable-header-content {
                            span {
                                // font-size: 1.25em;
                                font-weight: 300;
                            }
                        }
                    }

                    .rt-th.-sort-asc {
                        box-shadow: none !important;
                        &:before {
                            content: "▲";
                            float: right;
                        }
                    } 
                    
                    .rt-th.-sort-desc {
                        box-shadow: none !important;
                        &:before {
                          content: "▼";
                          float: right;
                        }
                    }
                }
            }

            .rt-tbody {
                .rt-tr {
                    .rt-td {
                        text-align: center;
                        vertical-align: middle;
                        align-self: center;
                        .actionsMenu {
                            button {
                                margin: 0px 3px 3px 0px;
                            }
                        }
                    }
                }
            }
        }
    }
}