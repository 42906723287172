.giving-central {
    .GiveEvents {
        .dynamic-card {
            .event-chart {
                .card-content {
                    .line-chart-container {
                        .charts {
                            .line-Chart {
                                .recharts-responsive-container {
                                    .recharts-wrapper {
                                        position: absolute;
                                        right: 19px;
                                    }
                                }
                            }

                            .area-chart {
                                .recharts-responsive-container {
                                    .recharts-wrapper {
                                        position: absolute;
                                        right: 19px;
                                    }
                                }
                            }

                            .bar-chart {
                                .recharts-responsive-container {
                                    .recharts-wrapper {
                                        position: absolute;
                                        right: 17px;
                                    }
                                }
                            }
                        }

                        .rechart-text-chart {
                            font-size: 16px;
                        }

                        .rechart-text {
                            font-size: 16px;
                        }

                        .event-icons {
                            display: flex;
                            justify-content: end;
                            color: #666666;
                            cursor: pointer;
                        }

                        .event-icons span:nth-child(2) {
                            max-width: 38px;
                            padding-left: 10px;
                        }

                        .event-icons-download span:nth-child(2) {
                            max-width: 50px !important;
                        }

                    }
                }

                .card-title {
                    font-size: 15px;
                    font-weight: 900;
                    font-family: 'Montserrat';
                }
            }

            .avg-chart {
                .card-content {
                    .line-chart-container {
                        .charts {
                            .recharts-responsive-container {
                                .recharts-wrapper {
                                    position: absolute;
                                    right: 19px;
                                }
                            }
                        }

                        .event-icons {
                            display: flex;
                            justify-content: end;
                            color: #666666;
                            cursor: pointer;

                            .avg-download-icon {
                                width: 38px;
                            }
                        }

                        .event-icons-download {
                            display: flex;
                            justify-content: space-between;
                            color: #666666;
                            cursor: pointer;
                        }

                        .event-icons span:nth-child(2) {
                            max-width: 38px;
                            padding-left: 10px;
                        }

                        .event-icons-download span:nth-child(2) {
                            max-width: 50px !important;
                        }

                        .download-icon {
                            display: flex;
                            justify-content: end;
                            align-items: center;

                            .icon-title-icon {
                                width: 40px;
                            }
                        }
                    }
                }

                .card-title {
                    font-size: 15px;
                    font-weight: 900;
                    font-family: 'Montserrat';
                }
            }

            .city-chart {
                .event-icons {
                    display: flex;
                    justify-content: end;
                    color: #666666;
                    cursor: pointer;
                }

                .event-icons span:nth-child(2) {
                    max-width: 38px;
                    padding-left: 10px;
                }

                .event-icons-download span:nth-child(2) {
                    max-width: 50px !important;
                }

                .card-title {
                    font-size: 15px;
                    font-weight: 900;
                    font-family: 'Montserrat';
                }
            }

            .giving-central-card {
                .card-title {
                    font-size: 15px;
                    font-weight: 900;
                    font-family: 'Montserrat';
                }
            }
        }
        .dynamic-card .dashboard-row {
            margin-right: 0px;
        }
    }

    .date-range-picker {
        margin-bottom: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        height: 40px;
        position: sticky;
        top: 50px;
        z-index: 10;

        .btn-group {
            margin: 0px;
        }

        .date-range-btn {
            background: white;
            color: black;
            border: none;
            box-shadow: none;
            text-transform: none;
            font-size: 15px;
            padding: 4px 30px;
        }

        .btn-today {
            background-color: #409fbd !important;
            border-color: #409fbd !important;
            color: white !important;
            height: 40px;
        }

        .btn-group.open>.dropdown-toggle.btn.btn-primary {
            background-color: #409fbd;
            border-color: #409fbd;
            color: white;
        }

        .btn.btn-primary,
        .btn.btn-primary:hover,
        .btn.btn-primary:focus,
        .btn.btn-primary:active,
        .btn.btn-primary.active,
        .btn.btn-primary:active:focus,
        .btn.btn-primary:active:hover {
            background: white;
            color: black;
            border: none;
            box-shadow: none;
        }

        .dropdown-menu {
            min-width: 140px !important;
            border-radius: 5px;
            color: #606060;

            .dropdown-list:hover {
                background-color: #409fbd;
                color: white;
            }

            .dropdown-list-active {
                background-color: #409fbd;
                color: white;
            }
        }

        .dropdown-menu:nth-child(2) {
            min-width: 145px !important;
        }

        .date-display {
            font-family: 'Montserrat';
            font-size: 15px !important;
            font-weight: 400 !important;
            line-height: 14.63px !important;
            padding-right: 10px;
            display: flex;

            .date {
                font-family: 'Montserrat';
                font-size: 15px !important;
                font-weight: 700 !important;
                line-height: 14.63px !important;
            }
        }
    }

    .shadow {
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    }

    .date-range-picker .btn-group:nth-child(1) {
        border-radius: 5px 0 0 5px;
    }
}

.download-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        text-align: center;
        font-size: x-large;
        color: #555555;
        margin: 0;
    }

    strong {
        font-size: 2.5rem;
        font-weight: bold;
        text-align: center;
    }

    span {
        font-size: 15px !important;
        font-weight: 300 !important;
        text-align: center;
        color: #666666;
    }

    .profile {
        padding: 30px;
    }

    .send-download-link {
        background-color: #409fbd !important;
        border-color: #409fbd !important;
        height: auto;
        padding: 10px;
        width: 230px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        span {
            color: white !important;
        }
    }

    img {
        border-radius: 50%;
    }
}

.search-wrapper {
    position: relative;

    .search_data {
        width: 100%;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 5px 5px 5px 30px;
        box-sizing: border-box;
    }

    .search-icon {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        color: #999;
    }
}

.giving-central-date-picker {
    .ant-modal-content {
        padding: 10px 10px 1px;
    }
}

@media (max-width: 1160px) {
    .giving-central {
        .date-range-picker {
            .date-display {
                display: block;
            }
        }
    }
}