.avg-chart {

    .footer-rechart {
        display: flex;
        align-items: center;
        color: grey;
        padding-left: 22px !important;

        .material-icons {
            color: rgb(229, 149, 19);
            font-size: 18px;
        }
    }

    .card-header-icon {
        background: linear-gradient(60deg,#D50003,#BF0D3E);
        position: absolute;
    }

    .card-content {
        .avg-date-range{
            padding: 0px 0px 0px 100px;
        }
    }

    .tab-container {
        display: flex;
        flex-wrap: wrap;
        padding-left: 20px;

        .btn-group-sm {
            .btn {
                font-size: 13px;
                font-family: 'Montserrat';
                text-transform: none;
            }
        }

        .tabs {
            background: #1D617F;
            color: white;
        }

        .tabs:hover {
            background: #70bdd4;
        }

        .tabshover {
            background: #2E93B2;
        }
    }

    .tab-container {
        flex-direction: column;

        .dashbaord-chart-containt {

            .tabs-main-container {
                margin-top: 0px !important;
                word-break: break-all;
            }

            .tabs-containt {
                display: flex;
                align-items: center;

                .average-icon1 {
                    height: 4px;
                    width: 20px;
                    background-color: #4c9ef5;
                    padding-left: 15px;
                    margin-right: 3px;
                }

                .average-icon2 {
                    height: 4px;
                    width: 20px;
                    border: 2px dotted white;
                    background-color: rgb(232, 29, 29);
                    padding-left: 15px;
                    margin-right: 3px;
                }

                .revenue-icon3 {
                    height: 15px;
                    width: 15px;
                    background-color: rgba(219, 190, 25, 0.776);
                    padding-left: 15px;
                }

                .revenue-icon4 {
                    height: 15px;
                    width: 15px;
                    background-color: green;
                    padding-left: 15px;
                }

                .revenue-icon5 {
                    height: 15px;
                    width: 15px;
                    background-color: #7B68EE;
                    padding-left: 15px;
                }

                .icons-containt {
                    .tooltip {
                        cursor: pointer;
                        position: relative;
                        font-size: 12px;
                        line-height: 0;
                        vertical-align: baseline;
                    }
                }

            }
        }
    }

    .line-chart-container {
        .recharts-tooltip-wrapper {
            border: 0.1px solid rgb(181, 179, 174);
            background: white;
            padding: 10px 10px 0px 10px;
            outline: 0;
        }

        .custom-tooltip {
            .date {
                color: #000;
                margin-bottom: 3px;
            }

            .average {
                color: #4c9ef5;
                margin-bottom: 5px;
            }
            
            .central-tooltip {
                color: #4c9ef5;
                margin-bottom: 5px;
                font-size: 1.3rem;
            }

            .y {
                color: red;
                margin-bottom: 5px;
            }

            .creative {
                color: rgba(219, 190, 25, 0.776);
                margin-bottom: 5px;

            }

            .conversions {
                color: green;
                margin-bottom: 5px;
            }

            .zipcode {
                color: #7B68EE;
                margin-bottom: 5px;
            }
        }

        .charts {
            position: relative;
        }

        .recharts-surface {
            font-family: 'Montserrat';
        }

        .rechart-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: grey;
            font-size: 20px;
            font-weight: 500;
            text-align: center;
        }
    }
}